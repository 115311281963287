<template>
  <div class="grid">
    <Toast />
    <div class="col-12">
      <div class="card">
        <div>
          <Toast />
          <AutoComplete
            :multiple="true"
            v-model="selectedHashtags"
            :suggestions="filteredHashtags"
            @complete="searchHashtags($event)"
            field="label"
          />
          <Button label="Search" @click="filterByHashtag" />
          <DataTable :value="filteredIncidents" style="margin-top:20px;">
            <Column field="incident" header="ID"></Column>
            <Column field="hashtags" header="Hashtags"></Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import UserService from "../service/UserService";
// import ArcherService from "../service/ArcherService";
import IncidentService from "../service/IncidentService.js";
// import Util from "../service/Util";

export default {
  // https://graph.microsoft.com/v1.0/users?$search="mail:thiago.cos"&$orderby=displayName&$count=true
  // campo de "sugerir hashtag", depois o time de RO decide se é válido
  data() {
    return {
      selectedHashtags: [],
      filteredHashtags: [],
      hashtags: IncidentService.hashtags,
      filteredIncidents: [],
    };
  },
  watch: {
    dateOcurrence() {
      this.dateDiscovery = null;
    },
  },

  mounted() {},
  methods: {
    parseResponseFilterByHashtag(resp){
      this.filteredIncidents = []
      var mapIncidents = {}
      for(var i= 0;i<resp.data.length;i++){
        mapIncidents[resp.data[i].incident] = ""
      }
      for(i= 0;i<resp.data.length;i++){
        mapIncidents[resp.data[i].incident] = mapIncidents[resp.data[i].incident] + resp.data[i].hashtag + ", "
      }

      for (var incident in mapIncidents) {
        this.filteredIncidents.push({"incident": incident, "hashtags": mapIncidents[incident].substring(0, mapIncidents[incident].length - 2)})
      }
    },
    filterByHashtag() {
      var hashtags = [];
      for (var i = 0; i < this.selectedHashtags.length; i++) {
        hashtags.push(this.selectedHashtags[i].value);
      }
      IncidentService.filterByHashtag(hashtags).then((resp) =>this.parseResponseFilterByHashtag(resp))
    },
    searchHashtags(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredHashtags = [...this.hashtags];
        } else {
          this.filteredHashtags = this.hashtags.filter((hashtag) => {
            return hashtag.label
              .toLowerCase()
              .includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
  },
};
</script>

<style scoped>
</style>